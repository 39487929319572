  <template>
  <template v-if="details">
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-wallet me-3"></i>{{ $t("effect_types.account") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.account,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.account }}</router-link
        >

        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(effectDetails.account)"
          >{{ $t("effect_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-tag-fill me-3"></i>{{ $t("effect_types.label") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.account,
            },
          }"
          class="text-decoration-none font-monospace fw-bold text-dark small"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
          :data-bs-original-title="$t('effect_types.label')"
          v-tooltip
          >{{
            accountLabels.data[effectDetails.account]
              ? accountLabels.data[effectDetails.account].label.name
              : $t("effect_types.unknown")
          }}</router-link
        >
        <router-link
          :to="{
            name: 'DirectoryUpdateRoute',
            query: {
              account: effectDetails.account,
            },
          }"
          class="float-end text-decoration-none"
          >{{ $t("effect_types.update") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-shield-exclamation me-3"></i
        >{{ $t("effect_types.trustor") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.trustor,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.trustor }}</router-link
        >

        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(effectDetails.trustor)"
          >{{ $t("effect_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-shield-exclamation me-3"></i
        >{{ $t("effect_types.trustor") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.trustor,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.trustor }}</router-link
        >
      </div>
    </li></template
  >
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              :to="{
                name: 'EffectDetailsRoute',
                params: {
                  eff_id: effectDetails.id,
                },
              }"
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('effect_types.effect') + idx
              } <br /> ${$t('effect_types.effs.trustline_deauthorized')}`"
              v-tooltip
            >
              <i
                class="
                  bi bi-shield-check
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'EffectDetailsRoute',
                      params: {
                        eff_id: effectDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('effect_types.effect_type')"
                    v-tooltip
                  >
                    {{ $t("effect_types.effs.trustline_deauthorized") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'EffectDetailsRoute',
                        params: {
                          eff_id: effectDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.effect_id')"
                      v-tooltip
                    >
                      {{ effectDetails.id }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div class="col-xxl-3 d-flex bg-light align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: effectDetails.account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('effect_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[effectDetails.account]
                        ? accountLabels.data[effectDetails.account].label.name
                        : $t("effect_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: effectDetails.account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.account')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: effectDetails.account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="effectDetails.account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: effectDetails.trustor,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('effect_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[effectDetails.trustor]
                        ? accountLabels.data[effectDetails.trustor].label.name
                        : $t("effect_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: effectDetails.trustor,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.trustor')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: effectDetails.trustor,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="effectDetails.trustor" />
                </div>
              </div>
              <div
                class="
                  col-xxl-3
                  ms-xxl-auto
                  align-items-center
                  d-flex
                  bg-light
                  rounded
                "
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="effectDetails.asset_type"
                  :code="effectDetails.asset_code"
                  :issuer="effectDetails.account"
                >
                  <img
                    v-if="effectDetails.asset_type === 'native'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="xlmLogo"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="assetMeta?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="assetMeta?.image"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="effectDetails.asset_type"
                      :code="effectDetails.asset_code"
                      :issuer="effectDetails.account"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.asset_name')"
                      v-tooltip
                      >{{
                        effectDetails.asset_type === "native"
                          ? $t("effect_types.xlm")
                          : effectDetails.asset_code
                      }}</asset-details-link
                    >
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="effectDetails.asset_type === 'native'"
                      :type="effectDetails.asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('effect_types.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("effect_types.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="effectDetails.asset_type"
                      :code="effectDetails.asset_code"
                      :issuer="effectDetails.account"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.asset_issuer')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: effectDetails.account,
                      }"
                    ></asset-details-link>
                    <span
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="
                        formatDate(effectDetails.created_at)
                      "
                      v-tooltip
                      >{{
                        formatDateElapsedTime(effectDetails.created_at)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "TrustlineDeauthorized",
  components: { AssetDetailsLink, AccountLabel },
  props: {
    effectDetails: Object,
    idx: Number,
    assetMeta: Object,
    xlmLogo: String,
    details: Boolean,
  },
  setup() {
    const store = useStore();
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    return { formatDateElapsedTime, formatDate, accountLabels, copy };
  },
});
</script>
