  <template>
  <template v-if="details"
    ><li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-wallet me-3"></i>{{ $t("effect_types.account") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.account,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.account }}</router-link
        >

        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(effectDetails.account)"
          >{{ $t("effect_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-tag-fill me-3"></i>{{ $t("effect_types.label") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.account,
            },
          }"
          class="text-decoration-none font-monospace fw-bold text-dark small"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
          :data-bs-original-title="$t('effect_types.label')"
          v-tooltip
          >{{
            accountLabels.data[effectDetails.account]
              ? accountLabels.data[effectDetails.account].label.name
              : $t("effect_types.unknown")
          }}</router-link
        >
        <router-link
          :to="{
            name: 'DirectoryUpdateRoute',
            query: {
              account: effectDetails.account,
            },
          }"
          class="float-end text-decoration-none"
          >{{ $t("effect_types.update") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-person me-3"></i>{{ $t("effect_types.former_sponsor") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.former_sponsor,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.former_sponsor }}</router-link
        >

        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(effectDetails.former_sponsor)"
          >{{ $t("effect_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-person me-3"></i>{{ $t("effect_types.new_sponsor") }}
      </div>
      <div class="w-75 py-2 px-3">
        <router-link
          :to="{
            name: 'AccountDetailsRoute',
            params: {
              acc_id: effectDetails.new_sponsor,
            },
          }"
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.new_sponsor }}</router-link
        >

        <router-link
          to="#"
          class="float-end text-decoration-none"
          @click="copy(effectDetails.new_sponsor)"
          >{{ $t("effect_types.copy") }}</router-link
        >
      </div>
    </li>
    <li class="list-group-item d-flex p-0 border-0">
      <div class="w-25 py-2 px-3 bg-light">
        <i class="bi bi-123 me-3"></i>{{ $t("effect_types.data_name") }}
      </div>
      <div class="w-75 py-2 px-3">
        <span
          class="align-self-center small font-monospace text-decoration-none"
          >{{ effectDetails.data_name }}</span
        >
      </div>
    </li></template
  >
  <template v-else>
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              :to="{
                name: 'EffectDetailsRoute',
                params: {
                  eff_id: effectDetails.id,
                },
              }"
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${
                $t('effect_types.effect') + idx
              } <br /> ${$t('effect_types.effs.data_sponsorship_updated')}`"
              v-tooltip
            >
              <i
                class="bi bi-person fs-5 align-self-center mx-auto text-primary"
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'EffectDetailsRoute',
                      params: {
                        eff_id: effectDetails.id,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('effect_types.effect_type')"
                    v-tooltip
                  >
                    {{ $t("effect_types.effs.data_sponsorship_updated") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'EffectDetailsRoute',
                        params: {
                          eff_id: effectDetails.id,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.effect_id')"
                      v-tooltip
                    >
                      {{ effectDetails.id }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div class="col-xxl-3 d-flex bg-light align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: effectDetails.account,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('effect_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[effectDetails.account]
                        ? accountLabels.data[effectDetails.account].label.name
                        : $t("effect_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: effectDetails.account,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.account')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: effectDetails.account,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="effectDetails.account" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: effectDetails.new_sponsor,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('effect_types.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[effectDetails.new_sponsor]
                        ? accountLabels.data[effectDetails.new_sponsor].label
                            .name
                        : $t("effect_types.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: effectDetails.new_sponsor,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('effect_types.new_sponsor')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: effectDetails.new_sponsor,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="effectDetails.new_sponsor" />
                </div>
              </div>
              <div
                class="
                  ms-xxl-auto
                  col-xxl-3
                  align-items-center
                  d-flex
                  bg-light
                  rounded
                "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('effect_types.operation_timestamp')"
                v-tooltip
              >
                <div class="flex-shrink-0 mx-2">
                  <i class="bi bi-clock fs-4"></i>
                </div>
                <div
                  class="flex-grow-1 text-dark text-end small font-monospace"
                >
                  <b class="small">{{
                    formatDate(effectDetails.created_at)
                  }}</b>
                  <p class="small mb-0">
                    {{ formatDateElapsedTime(effectDetails.created_at) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "DataSponsorshipUpdated",
  components: { AccountLabel },
  props: {
    effectDetails: Object,
    idx: Number,
    assetMeta: Object,
    xlmLogo: String,
    details: Boolean,
  },
  setup() {
    const store = useStore();
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    return { formatDateElapsedTime, formatDate, accountLabels, copy };
  },
});
</script>
