
<script>
import { defineComponent, h, computed } from "vue";
import { useStore } from "vuex";
import AccountCreated from "./types/account/AccountCreated.vue";
import AccountRemoved from "./types/account/AccountRemoved.vue";
import AccountCredited from "./types/account/AccountCredited.vue";
import AccountDebited from "./types/account/AccountDebited.vue";
import AccountThresholdsUpdated from "./types/account/AccountThresholdsUpdated.vue";
import AccountHomeDomainUpdated from "./types/account/AccountHomeDomainUpdated.vue";
import AccountFlagsUpdated from "./types/account/AccountFlagsUpdated.vue";
import AccountInflationDestinationUpdated from "./types/account/AccountInflationDestinationUpdated.vue";
import SignerCreated from "./types/signer/SignerCreated.vue";
import SignerRemoved from "./types/signer/SignerRemoved.vue";
import SignerUpdated from "./types/signer/SignerUpdated.vue";
import TrustlineCreated from "./types/trustline/TrustlineCreated.vue";
import TrustlineFlagsUpdated from "./types/trustline/TrustlineFlagsUpdated.vue";
import TrustlineRemoved from "./types/trustline/TrustlineRemoved.vue";
import TrustlineUpdated from "./types/trustline/TrustlineUpdated.vue";
import TrustlineAuthorized from "./types/trustline/TrustlineAuthorized.vue";
import TrustlineDeauthorized from "./types/trustline/TrustlineDeauthorized.vue";
import Trade from "./types/trading/Trade.vue";
import DataCreated from "./types/data/DataCreated.vue";
import DataUpdated from "./types/data/DataUpdated.vue";
import DataRemoved from "./types/data/DataRemoved.vue";
import ClaimableBalanceCreated from "./types/claimable_balance/ClaimableBalanceCreated.vue";
import ClaimableBalanceClaimantCreated from "./types/claimable_balance/ClaimableBalanceClaimantCreated.vue";
import ClaimableBalanceClaimed from "./types/claimable_balance/ClaimableBalanceClaimed.vue";
import TrustlineSponsorshipCreated from "./types/sponsorship/TrustlineSponsorshipCreated.vue";
import TrustlineSponsorshipUpdated from "./types/sponsorship/TrustlineSponsorshipUpdated.vue";
import TrustlineSponsorshipRemoved from "./types/sponsorship/TrustlineSponsorshipRemoved.vue";
import AccountSponsorshipCreated from "./types/sponsorship/AccountSponsorshipCreated.vue";
import AccountSponsorshipUpdated from "./types/sponsorship/AccountSponsorshipUpdated.vue";
import AccountSponsorshipRemoved from "./types/sponsorship/AccountSponsorshipRemoved.vue";
import DataSponsorshipCreated from "./types/sponsorship/DataSponsorshipCreated.vue";
import DataSponsorshipUpdated from "./types/sponsorship/DataSponsorshipUpdated.vue";
import DataSponsorshipRemoved from "./types/sponsorship/DataSponsorshipRemoved.vue";
import SequenceBumped from "./types/data/SequenceBumped.vue";
import ClaimableBalanceSponsorshipCreated from "./types/sponsorship/ClaimableBalanceSponsorshipCreated.vue";
import ClaimableBalanceSponsorshipRemoved from "./types/sponsorship/ClaimableBalanceSponsorshipRemoved.vue";
import ClaimableBalanceSponsorshipUpdated from "./types/sponsorship/ClaimableBalanceSponsorshipUpdated.vue";
import SignerSponsorshipCreated from "./types/sponsorship/SignerSponsorshipCreated.vue";
import SignerSponsorshipUpdated from "./types/sponsorship/SignerSponsorshipUpdated.vue";
import SignerSponsorshipRemoved from "./types/sponsorship/SignerSponsorshipRemoved.vue";
import LiquidityPoolCreated from "./types/liquidity_pool/LiquidityPoolCreated.vue";
import LiquidityPoolDeposited from "./types/liquidity_pool/LiquidityPoolDeposited.vue";
import LiquidityPoolWithdrew from "./types/liquidity_pool/LiquidityPoolWithdrew.vue";
import LiquidityPoolTrade from "./types/liquidity_pool/LiquidityPoolTrade.vue";
import LiquidityPoolRemoved from "./types/liquidity_pool/LiquidityPoolRemoved.vue";
import ClaimableBalanceClawedBack from "./types/clawback/ClaimableBalanceClawedBack.vue";

export default defineComponent({
  name: "EffectInfo",
  props: {
    effectDetails: Object,
    idx: Number,
    details: Boolean,
  },
  components() {},
  setup(props) {
    const store = useStore();
    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data
    );
    const xlmLogo = require("@/assets/images/assets/xlm.png");

    return () => {
      switch (props.effectDetails.type) {
        case "account_created":
          return h(AccountCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_removed":
          return h(AccountRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_credited":
          return h(AccountCredited, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            details: props.details,
          });
        case "account_debited":
          return h(AccountDebited, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            details: props.details,
          });
        case "account_thresholds_updated":
          return h(AccountThresholdsUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_home_domain_updated":
          return h(AccountHomeDomainUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_flags_updated":
          return h(AccountFlagsUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_inflation_destination_updated":
          return h(AccountInflationDestinationUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "signer_created":
          return h(SignerCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "signer_removed":
          return h(SignerRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "signer_updated":
          return h(SignerUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "trustline_created":
          return h(TrustlineCreated, {
            effectDetails: props.effectDetails,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            idx: props.idx,
            details: props.details,
          });
        case "trustline_removed":
          return h(TrustlineRemoved, {
            effectDetails: props.effectDetails,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            idx: props.idx,
            details: props.details,
          });
        case "trustline_updated":
          return h(TrustlineUpdated, {
            effectDetails: props.effectDetails,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            idx: props.idx,
            details: props.details,
          });
        case "trustline_flags_updated":
          return h(TrustlineFlagsUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "trustline_authorized":
          return h(TrustlineAuthorized, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            details: props.details,
          });
        case "trustline_deauthorized":
          return h(TrustlineDeauthorized, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.effectDetails.asset_code +
                  "_" +
                  props.effectDetails.asset_issuer
              ],
            details: props.details,
          });
        case "trade":
          return h(Trade, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            soldAssetMeta:
              assetsMeta.value[
                props.effectDetails.sold_asset_code +
                  "_" +
                  props.effectDetails.sold_asset_issuer
              ],
            boughtAssetMeta:
              assetsMeta.value[
                props.effectDetails.bought_asset_code +
                  "_" +
                  props.effectDetails.bought_asset_issuer
              ],
            details: props.details,
          });
        case "data_created":
          return h(DataCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "data_updated":
          return h(DataUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "data_removed":
          return h(DataRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "sequence_bumped":
          return h(SequenceBumped, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "claimable_balance_created": {
          const assetArr = props.effectDetails.asset.split(":");
          return h(ClaimableBalanceCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "claimable_balance_claimant_created": {
          const assetArr = props.effectDetails.asset.split(":");
          return h(ClaimableBalanceClaimantCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "claimable_balance_claimed": {
          const assetArr = props.effectDetails.asset.split(":");
          return h(ClaimableBalanceClaimed, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "trustline_sponsorship_created": {
          const assetArr = props.effectDetails.asset.split(":");
          return h(TrustlineSponsorshipCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "trustline_sponsorship_updated": {
          const assetArr = props.effectDetails.asset.split(":");
          return h(TrustlineSponsorshipUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "trustline_sponsorship_removed": {
          const assetArr = props.effectDetails.asset.split(":");
          return h(TrustlineSponsorshipRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta: assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            details: props.details,
          });
        }
        case "account_sponsorship_created":
          return h(AccountSponsorshipCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_sponsorship_removed":
          return h(AccountSponsorshipRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "account_sponsorship_updated":
          return h(AccountSponsorshipUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "data_sponsorship_created":
          return h(DataSponsorshipCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "data_sponsorship_removed":
          return h(DataSponsorshipRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "data_sponsorship_updated":
          return h(DataSponsorshipUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "claimable_balance_sponsorship_created":
          return h(ClaimableBalanceSponsorshipCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "claimable_balance_sponsorship_updated":
          return h(ClaimableBalanceSponsorshipUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "claimable_balance_sponsorship_removed":
          return h(ClaimableBalanceSponsorshipRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "signer_sponsorship_created":
          return h(SignerSponsorshipCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "signer_sponsorship_updated":
          return h(SignerSponsorshipUpdated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "signer_sponsorship_removed":
          return h(SignerSponsorshipRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        case "liquidity_pool_created": {
          const assetArr =
            props.effectDetails.liquidity_pool.reserves[0].asset.split(":");
          const assetArr2 =
            props.effectDetails.liquidity_pool.reserves[1].asset.split(":");
          return h(LiquidityPoolCreated, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            reservesAssetMeta:
              assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            reservesAssetMeta2:
              assetsMeta.value[assetArr2[0] + "_" + assetArr2[1]],
            details: props.details,
          });
        }
        case "liquidity_pool_deposited": {
          const assetArr =
            props.effectDetails.reserves_deposited[0].asset.split(":");
          const assetArr2 =
            props.effectDetails.reserves_deposited[1].asset.split(":");
          return h(LiquidityPoolDeposited, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            reservesAssetMeta:
              assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            reservesAssetMeta2:
              assetsMeta.value[assetArr2[0] + "_" + assetArr2[1]],
            details: props.details,
          });
        }
        case "liquidity_pool_withdrew": {
          const assetArr =
            props.effectDetails.reserves_received[0].asset.split(":");
          const assetArr2 =
            props.effectDetails.reserves_received[1].asset.split(":");
          return h(LiquidityPoolWithdrew, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            reservesAssetMeta:
              assetsMeta.value[assetArr[0] + "_" + assetArr[1]],
            reservesAssetMeta2:
              assetsMeta.value[assetArr2[0] + "_" + assetArr2[1]],
            details: props.details,
          });
        }
        case "liquidity_pool_trade": {
          const soldArr = props.effectDetails.sold.asset.split(":");
          const boughtArr = props.effectDetails.bought.asset.split(":");
          return h(LiquidityPoolTrade, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            xlmLogo,
            soldAssetMeta: assetsMeta.value[soldArr[0] + "_" + soldArr[1]],
            boughtAssetMeta:
              assetsMeta.value[boughtArr[0] + "_" + boughtArr[1]],
            details: props.details,
          });
        }
        case "liquidity_pool_removed": {
          return h(LiquidityPoolRemoved, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        }
        case "claimable_balance_clawed_back": {
          return h(ClaimableBalanceClawedBack, {
            effectDetails: props.effectDetails,
            idx: props.idx,
            details: props.details,
          });
        }
        default:
          return [];
      }
    };
  },
});
</script>
